import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  LoginOtpResponse,
  MobileSearchResponse,
  OtpType,
  OtpVerificationPayload,
  OtpVerificationResponse,
  TokenDetails,
} from '@pv-frontend/authentication';
import { MobileNumber } from '@pv-frontend/pv-shared-components/mobile-input';
import {
  PV_USER_SERVICE,
  PvUserService,
} from '@pv-frontend/pv-shared-services/pv-user-service';
import { Observable, Subject, takeUntil } from 'rxjs';

import { environment } from '../../../environments/environment';

const OtpVerificationType: OtpType = {
  ACTIVATION: 'activation',
  LOGIN: 'login',
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private destroy$: Subject<void> = new Subject<void>();

  public constructor(
    private http: HttpClient,
    @Inject(PV_USER_SERVICE) public pvUserService: PvUserService
  ) {
    this.checkTokenEXpiry();
    this.pvUserService.userLoggedInObservable$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isLoggedIn: boolean) => {
        isLoggedIn && this.checkTokenEXpiry();
      });
  }

  private checkTokenEXpiry(): void {
    const token: string = this.pvUserService.getToken();
    if (token) {
      const tokenDetails: TokenDetails = JSON.parse(
        atob(token.split('.')?.[1])
      );
      if (!tokenDetails) return;
      const expiresOn = new Date(tokenDetails.exp * 1000);
      if (expiresOn < new Date()) {
        this.pvUserService.updateTokenExpiry(true);
      } else {
        setTimeout(
          () => {
            this.pvUserService.updateTokenExpiry(true);
          },
          Math.floor(tokenDetails.exp * 1000 - new Date().getTime())
        );
      }
    }
  }

  public searchMobileNumber(
    mobileNumber: string
  ): Observable<MobileSearchResponse> {
    const payload = { mobile: mobileNumber };
    return this.http.post<MobileSearchResponse>(
      `${environment.apiClientsServiceUrl}/users/search`,
      payload
    );
  }

  public generateOtp(
    userId: string,
    mobileNumber: MobileNumber | null
  ): Observable<LoginOtpResponse> {
    return this.http.post<LoginOtpResponse>(
      `${environment.apiClientsServiceUrl}/users/${userId}/otps`,
      {
        mobile: mobileNumber,
        otp_type: 'login',
      }
    );
  }

  public getRemainingMinutes(toDateTime: string | null): number {
    if (!toDateTime) return 0;
    const currentDate: Date = new Date();
    const targetDate: Date = new Date(toDateTime);

    const timeDifference = targetDate.getTime() - currentDate.getTime();
    const remainingMinutes = Math.floor(timeDifference / (1000 * 60));

    return remainingMinutes;
  }

  public verifyOtp(
    userId: string,
    otpNumber: string,
    isUserCreation: boolean = false
  ): Observable<OtpVerificationResponse> {
    const otpVerificationPayload: OtpVerificationPayload = {
      code: otpNumber,
      otp_type: isUserCreation
        ? OtpVerificationType.ACTIVATION
        : OtpVerificationType.LOGIN,
    };
    return this.http.post<OtpVerificationResponse>(
      `${environment.apiClientsServiceUrl}/users/${userId}/otps/verify`,
      otpVerificationPayload
    );
  }

  public isUserAuthenticated(): boolean {
    return this.pvUserService.isUserAuthenticated();
  }
}
