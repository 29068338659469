import { GvEnvironment } from '@pv-frontend/gift-voucher-journey';

export interface EnvInterface extends GvEnvironment {
  clientId: string;
  projectId: string;
  apiUrlBsV1: string;
  apiUrlBsV1Internal: string;
  apiUrlLsV1: string;
  corePath: string;
  corePathV2: string;
  offerPath: string;
  offerPathV2: string;
  countryId: string;
  urlsToBeRemoved: string[];
  campaignPath: string;
  campaignId: string; // COMMENT: since this project has only one campaign, will be using it from env, so we don't have to make an extra api call
  sentryDsn: string;
  production: boolean;
}

export const environment: EnvInterface = {
  production: true,
  apiPerksServiceUrl: 'https://api-customer.poshvine.com/ps/v1',
  apiPerksServiceUrlV2: 'https://api-customer.poshvine.com/ps/v2',
  apiClientsServiceUrl: 'https://api-customer.poshvine.com/cs/v1',
  apiUrlPaV1: 'https://api-customer.poshvine.com/pa/v1/',
  apiBookingServiceUrl: 'https://api-customer.poshvine.com/bs/v1',
  clientId: 'b91d62e8-e40d-4fd2-89fc-62ec87f683b3',
  projectId: '4da84644-1278-46da-ba76-0e33b4c668e0',
  apiUrlBsV1: 'https://api-customer.poshvine.com/bs/v1/',
  apiUrlBsV1Internal: 'https://api-customer.poshvine.com/bs/internal',
  apiUrlLsV1: 'https://api-customer.poshvine.com/ls/v1/',
  corePath: 'https://api-customer-offerservice.poshvine.com/core_service/v1',
  corePathV2: 'https://api-customer.poshvine.com/cs/v1',
  offerPath: 'https://api-customer-offerservice.poshvine.com/offer_service/v1',
  offerPathV2: 'https://api-customer.poshvine.com/ps/v1',
  countryId: '625a3e8e-e493-421a-8ece-609f10b37442',
  campaignPath: 'https://api-customer.poshvine.com/cas/v1/',
  urlsToBeRemoved: [''],
  apiLoyaltyServiceUrl: 'https://api-customer.poshvine.com/ls/v1',
  campaignId: '8feb3ec7-ce4f-42a9-8707-ce3a63b341f3',
  apiBookingServiceUrlV2: '',
  sentryDsn:
    'https://bf90c28acfbebfd2e116f00b079ea0f4@o515678.ingest.us.sentry.io/4507454481367040',
};
