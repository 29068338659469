import { Injectable, inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { AuthService } from '../authentication/services/auth.service';

export const authGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  const router = inject(Router);
  return handleAuthGuard(authService, router);
};

function handleAuthGuard(authService: AuthService, router: Router): boolean {
  if (authService.isUserAuthenticated()) {
    return true;
  } else {
    router.navigate(['/login']);
    return false;
  }
}

export const authGuardFactory = (
  authService: AuthService,
  router: Router
): CanActivateFn => {
  return () => {
    return handleAuthGuard(authService, router);
  };
};

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  public constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  public canActivate = authGuardFactory(this.authService, this.router);
}
